<!--
 * @Description:业绩分成table
 * @Author: ChenXueLin
 * @Date: 2021-10-08 09:19:15
 * @LastEditTime: 2021-10-21 10:55:00
 * @LastEditors: ChenXueLin
-->
<template>
  <div :class="{ wrap: !searchFormVisiable }">
    <!-- 搜索头部 start -->
    <section
      class="search-wrapper fixed-section search-triangle"
      v-show="searchFormVisiable"
    >
      <el-form class="search-list" ref="searchForm" :model="searchForm">
        <el-form-item class="search-item--1" prop="orderNo">
          <el-input
            v-model="searchForm.orderNo"
            placeholder="业务员"
            title="业务员"
          ></el-input>
        </el-form-item>

        <el-form-item class="search-item--1" prop="orderNo">
          <el-input
            v-model="searchForm.orderNo"
            placeholder="分成比例"
            title="分成比例"
          ></el-input>
        </el-form-item>
        <el-form-item class="search-item--buttons">
          <el-button type="primary" @click="handleSearch">查询</el-button>
          <el-button class="reset" @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </section>
    <!-- 搜索头部 end -->

    <!-- 表格 start -->
    <section class="table-wrapper">
      <el-table border height="250px" :data="tableData" highlight-current-row>
        <!-- 序号 start -->
        <el-table-column
          label="序号"
          width="50"
          fixed="left"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{
                scope.$index +
                  1 +
                  (searchForm.pageIndex - 1) * searchForm.pageSize
              }}
            </span>
          </template>
        </el-table-column>
        <!-- 序号 end -->
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="column.fieldName === 'chargeCode'">
              <el-button type="text" @click="handleGoToBillDetail(row)">
                {{ row[column.fieldName] }}
              </el-button>
            </span>
            <span v-else>{{ row[column.fieldName] }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column> -->
      </el-table>
    </section>
    <!-- 表格 end -->

    <!-- 分页 start -->
    <section class="pagination-wrapper fixed-section">
      <el-pagination
        :page-size.sync="searchForm.pageSize"
        :current-page.sync="searchForm.pageIndex"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </section>
    <!-- 分页 end -->
  </div>
</template>
<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
export default {
  name: "workOrderList",
  data() {
    return {
      searchForm: {
        orderNo: "",
        typeId: "",
        secondType: "",
        pageIndex: 1,
        pageSize: 20
      },

      total: 0,
      columnData: [
        {
          fieldName: "chargeCode",
          display: true,
          fieldLabel: "业务员",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "invoiceName",
          display: true,
          fieldLabel: "分成比例",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [
        {
          chargeCode: "11111"
        },
        {
          chargeCode: "155551"
        }
      ] // 表格数据
    };
  },
  props: {
    searchFormVisiable: {
      type: Boolean,
      default: false
    }
  },
  mixins: [base, listPage, listPageReszie],

  computed: {},

  mounted() {},

  methods: {}
};
</script>
<style lang="scss" scoped>
.wrap {
  margin-top: 10px;
}
.el-form {
  margin-top: 0px;
}
</style>
